:root {
  --cWhite: #ffffff;
  --cPurple: #850ce3;
  --cBtn: rgba(255, 255, 255, 0.1);
  --cTransparent: rgba(255, 255, 255, 0.02);
  --cBorder: #3f3f3f;
  --cPurpleBtn: linear-gradient(180deg, #38ef7d 0%, #11998e 100%);
  --cPurpleBtnHover: linear-gradient(180deg, #11998e 0%, #1b837a 100%);
  --cBorderDark: rgba(255, 255, 255, 0.05);
  --cBorderLight: rgba(255, 255, 255, 0.4);
  --cLightGrey: #272727;
  --cOrange: linear-gradient(180deg, #ff9900 0%, #e48900 100%);
  --cOrangeText: #e48900;
  --cOrangeBg: rgba(228, 137, 0, 0.1);

  --sRadius: 10px;
  --sRadiusMedium: 12px;
  --sRadiusLarge: 16px;
  --sRadiusHuge: 20px;

  --sFontTiny: 1.1rem; //11px;
  --sFontSmall: 1.2rem; //12px;
  --sFontMedium: 1.4rem; //14px;
  --sFontBody: 1.6rem; //16px;
  --sFontLarge: 1.8rem; //18px;
  --sH1: 2.4rem; //24px;
}
