@import "src/styles/mixins";
//@import "src/styles/constants";

.sidebar {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 11;
  transition: 0.1s;
  overflow: visible;
  align-items: center;
  background-color: #0a0a0a;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);

  &-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding: 11px 0;

    @include respond(md) {
      padding: 7px 0;
    }
  }

  &.offset {
    background: #0a0a0a;
    backdrop-filter: blur(2px);
  }

  &.show {
    background: #0a0a0a !important;
    backdrop-filter: none !important;
  }

  &-right {
    display: flex;
    align-items: center;
  }

  &-links {
    display: flex;
    gap: 20px;
  }

  .header-sidebar {
    position: fixed;
    background: #0a0a0a;
    width: 100%;
    height: 1000px;
    top: -1100px;
    transition: 0.5s;
    left: 0;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    display: flex;
    z-index: 10;

    &.active {
      top: 65px;
      opacity: 1;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    width: 84px;

    &.logged {
      width: 314px;

      @include respond(md) {
        width: 100%;
      }
    }

    @include respond(md) {
      width: auto;
    }

    &-desk {
      @include respond(md) {
        display: none;
      }
    }

    &-mobile {
      display: none;

      img {
        width: 30px;
        height: 30px;
      }

      @include respond(md) {
        display: block;
      }
    }

    &-right {
      display: flex;
      width: 250px;
      align-items: center;

      @include respond(md) {
        width: auto;
      }
    }

    &-btn {
      background-color: initial;
      border: none;
      padding: 0;
    }

    @include respond(md) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &-navigation {
    &-item {
      display: flex;
      align-items: center;
      background-color: initial;
      border: none;
      outline: none;
      padding: 0 12px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: rgba(255, 255, 255, 0.6);
      position: relative;
      transition: 0.1s;
      text-decoration: none;

      &-coming {
        position: absolute;
        opacity: 0;
        display: none;
        transition: 0.15s;
        background: #ffffff;
        border: 1px solid #4090a5;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 9px;
        padding: 8px 13px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #8f9194;
        top: 30px;
        z-index: 2;
        left: 0;

        svg {
          top: -7px;
          z-index: 2;
          left: 10px;
          position: absolute;
        }
      }

      &-line {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 1.5px;
        border-radius: 100px;
        background: #38ef7d;
        bottom: -12px;
        left: 0;
      }

      &.active {
        color: #fff;

        .sidebar-navigation-item-line {
          opacity: 1;
        }
      }

      &:hover {
        &:disabled {
          .sidebar-navigation-item-coming {
            display: block;
            opacity: 1;
          }
        }
      }

      @include respond(md) {
        opacity: 0.6;
        margin-right: 0;
        color: #fff;
        padding-bottom: 20px;
        padding-top: 20px;
        width: 100%;
        border-bottom: 1px solid #1c1c1c;
        justify-content: center;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-connect {
    display: flex;
    position: relative;
    padding: 10px 22px;
    background: linear-gradient(180deg, #850ce3 0%, #5202a1 100%);
    border-radius: 14px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 20px;
    color: #ffffff;
    align-items: center;

    &-disconnect {
      margin-left: 10px;
    }

    &-icon {
      margin-right: 16px;
    }

    @include respond(md) {
    }
  }

  @include respond(md) {
    width: calc(100% - 40px);
    padding: 8px 20px;
    background: #0a0a0a;
    backdrop-filter: blur(2px);
    height: 50px;
  }
}
