.exchange_contact_form_wrapper {
  input:focus,
  textarea:focus,
  select:focus {
    outline: none; /* Убирает обводку */
    box-shadow: none; /* Убирает тень */
  }

  .mainBlock__form {
    margin-top: 208px;
    text-align: center;
  }

  .contactForm__form {
    width: 520px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #0d0d0d;
    margin: 0 auto;
    padding: 20px;
    z-index: 3;
    @media screen and (max-width: 900px) {
      width: 85%;
    }

    .button {
      position: relative;
      background: var(--cPurpleBtn);
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 14px 0;
      border-radius: var(--sRadiusMedium);
      background-image: var(--cPurpleBtn);
      border: none;
      color: var(--cWhite);
      font-weight: 700;
      font-size: var(--sFontBody);
      overflow: hidden;

      span {
        position: relative;
        z-index: 1;
      }

      &::before {
        position: absolute;
        content: "";
        inset: 0;
        background-image: var(--cPurpleBtnHover);
        z-index: 1;
        opacity: 0;
        transition: opacity 0.25s linear;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &--loading {
        background: grey;
        cursor: wait;

        &::before {
          content: "\f110";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          margin-right: 8px;
          animation: spin 1s linear infinite;
        }
      }
    }
  }

  .contactForm__submitBtn {
    margin-top: 40px;
    width: 100%;
    background-image: linear-gradient(180deg, #38ef7d 0%, #11998e 100%);

    &:hover {
      background-image: linear-gradient(180deg, #38ef7d 0%, #11998e 100%);
    }
  }

  h2 {
    margin-bottom: 40px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
}

@media screen and (max-width: 900px) {
  .exchange_contact_form_wrapper {
    width: calc(100% - 10%);
    .mainBlock__form {
      margin-top: 80px;
    }
    .contactForm__form {
      width: 85%;
      .text-input__wrapper {
        input {
          font-size: 16px;
          &::placeholder {
            font-size: 16px;
          }
        }
      }
    }
  }
}
