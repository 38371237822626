.creating__error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 40px;
  }

  h2 {
    margin-top: 32px;
    font-size: 24px;
  }

  & > span {
    margin-top: 16px;
    font-size: 14px;
    opacity: 0.8;
  }

  button {
    margin-top: 72px;
    span {
      font-size: 20px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
