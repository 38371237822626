.header__block {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 20px 0;

  @media screen and (max-width: 767px) {
    padding: 20px 17px;
  }

  @media screen and (min-width: 768px) {
    justify-content: center;
  }

  h1 {
    font-size: var(--sH1);
    margin: 0;
  }

  .button {
    position: absolute;
    right: 20px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .button_close {
    img {
      transition: all 0.4s ease;
      transform: rotate(0);
    }

    &:hover {
      img {
        transform: rotate(90deg);
      }
    }
  }
}

button {
  border: none;
  background: transparent;
  color: var(--cWhite);
}

.button {
  border: none;
  background: var(--cBtn);
  padding: 12px;
  border-radius: var(--sRadius);

  img {
    display: block;
  }
}

.header__billet {
  position: relative;
  padding: 4px;
  background: var(--cBtn);
  border: 1px solid var(--cBorderLight);
  border-radius: 100px;
  max-width: 160px;
  min-width: 100px;
  font-size: var(--sFontTiny);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header__navigation {
  top: calc(100% + -10px);
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 9px;

  .header__billet {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:first-of-type) {
      &::before {
        content: "";
        position: absolute;
        width: 10px;
        height: 1px;
        background: #888888;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.container {
  max-width: 1080px;
  margin: auto;
  padding: 0 10px;

  @media screen and (min-width: 1201px) {
    width: 100%;
  }
}

.container_border {
  border-top: 1px solid var(--cBorderDark);
}

.container_flex {
  display: flex;
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
