.value_range {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.4;
}

.value_range_last {
  margin-bottom: 40px;
}

.bridgeExchangeContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cBorder);
  background: #0d0d0d;
  border-radius: var(--sRadiusLarge);

  @media screen and (min-width: 768px) {
    width: 100%;
  }

  .button__images {
    position: relative;
    height: 20px;
    width: 24px;
  }

  .button_history {
    .button__outer,
    .button__inner {
      position: absolute;
      transform: rotate(0);
      transition: all 1s;
    }

    &:hover {
      .button__outer {
        transform: rotate(-360deg);
      }
    }
  }
}

.max-width-step1 {
  max-width: 620px;
  @media screen and (max-width: 768px) {
    max-width: 350px;
    width: 100%;
  }
}

.max-width-step2 {
  max-width: 720px;
  @media screen and (max-width: 768px) {
    max-width: 350px;
    width: 100%;
  }
}

.max-width-step3 {
  max-width: 520px;
  @media screen and (max-width: 768px) {
    max-width: 350px;
    width: 100%;
  }
}

.header__block__details {
  margin-top: 20px;
  padding: 0;
}

.details-unpit__container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
  justify-content: space-between;

  div {
    width: 100%;

    label {
      font-size: 12px;
      font-weight: 600;
      opacity: 0.4;
    }

    input {
      margin-top: 11px;
      width: 100%;
      color: white;
      border: none;
      padding: 5px;
      padding-left: 0;
      outline: none;
      font-size: 15px;
      font-weight: 500;
      &::placeholder {
        color: white;
        font-size: 15px;
        font-weight: 500;
        opacity: 0.4;
      }
    }
  }
  & > div {
    margin-top: 35px;
    padding-bottom: 3px;
    border-bottom: 1px solid #737373;
    width: 45%;
    &:nth-child(2n + 1) {
      margin-right: 40px;
    }
  }
}

.creating {
  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .creating__option__value {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .creating__value {
    margin: 15px 0 0;
    padding-bottom: 15px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 768px) {
      width: calculate(100% - 10%);
    }

    &:not([aria-disabled="true"]):hover {
      cursor: pointer;
    }
  }

  .creating__option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  .creating__label {
    font-size: var(--sFontSmall);
    opacity: 0.4;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .header__block__creating {
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0;
  }

  .progress-button {
    margin-top: 60px;
  }

  .creating__address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    padding-left: 12px;
    margin-top: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
  }

  .qr-code {
    display: inline-block;
    padding: 3px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 0;
  }

  .creating_wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 991px) {
  .details-unpit__container {
    flex-wrap: nowrap;
    flex-direction: column;

    & > div {
      width: 100%;
      margin-right: 0;
    }
  }

  .creating {
    display: flex;
    flex-direction: column;

    .header__block__creating {
      margin-bottom: 64px;
    }

    &_wrapper {
      flex-direction: column-reverse;
      align-items: center;
    }

    .options_wrapper {
      margin-top: 40px;
      margin-bottom: 9px;
      display: flex;
      flex-direction: column;
    }

    label {
      text-align: center;
    }

    .creating__address {
      justify-content: center;
      span {
        display: block;
        font-size: 16px;
        overflow-wrap: break-word;
        max-width: 245px;
        text-align: center;
      }
    }
  }
}
