.languageSwitcher {
  color: var(--cWhite);
  position: relative;
}

.languageSwitcher__button {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 5px;
  font-weight: 500;
  padding: 10px 0;
}

.languageSwitcher__country {
  display: flex;
  gap: 8px;
  align-items: center;
}

.languageSwitcher__languagesList {
  top: calc(100% + 19px);
  left: 50%;
  transform: translateX(-50%);
  list-style-type: none;
  position: absolute;
  background: #2A2A2A;
  border-radius: var(--sRadiusLarge);
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-weight: 500;
  z-index: 1;

  li {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    gap: 8px;
    padding: 14px 28px;
    cursor: pointer;

    &:hover {
      background: var(--cWhite);
      color: #2A2A2A;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--cBorderDark);
    }
  }
}
