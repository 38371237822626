.exchange-page {
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: 129rem;
    height: 80rem;
    left: -377px;
    top: 0;

    transform: translateY(35%);

    background: radial-gradient(50% 50% at 50% 50%, rgba(17, 153, 142, 0.4) 0%, rgba(17, 153, 142, 0) 100%);

    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 148rem;
    height: 95rem;
    right: -377px;
    top: 0;

    transform: translateY(35%);

    background: radial-gradient(50% 50% at 50% 50%, rgba(56, 239, 125, 0.4) 0%, rgba(56, 239, 125, 0) 100%);

    z-index: -2;
  }

  &__loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 2.4rem;
  }

  &__subtitle {
    color: rgba(255, 255, 255, 0.2);
    margin-top: 1rem;
    font-size: 1.6rem;
  }
}

.bridge-page {
  display: flex;
  flex-grow: 1;

  &::before {
    content: "";

    position: fixed;
    bottom: 20rem;
    left: 50%;
    width: 33.2rem;
    height: 3px;
    background: linear-gradient(180deg, #6f08c7 0%, rgba(128, 12, 222, 0) 100%);
    transform: rotate(135deg);
    transform-origin: left center;
    z-index: -1;
  }

  &__loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 2.4rem;
  }

  &__subtitle {
    color: rgba(255, 255, 255, 0.2);
    margin-top: 1rem;
    font-size: 1.6rem;
  }
}

.bridge_page__loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bridgeContainer {
  margin: 20px 12px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cBorder);
  background: var(--cTransparent);
  border-radius: var(--sRadiusLarge);

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 640px;
  }

  .button__images {
    position: relative;
    height: 20px;
    width: 24px;
  }

  .button_history {
    .button__outer,
    .button__inner {
      position: absolute;
      transform: rotate(0);
      transition: all 1s;
    }

    &:hover {
      .button__outer {
        transform: rotate(-360deg);
      }
    }
  }
}

.bridgeContainer__actions {
  padding: 0 24px 24px;
}

.bridgeContainer__nextBtn {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 14px 0;
  border-radius: var(--sRadiusMedium);
  background-image: var(--cPurpleBtn);
  border: none;
  color: var(--cWhite);
  font-weight: 700;
  font-size: var(--sFontBody);
  overflow: hidden;

  span {
    position: relative;
    z-index: 1;
  }

  .loader {
    background: grey;
    cursor: wait;

    &::before {
      content: "\f110";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-right: 8px;
      animation: spin 1s linear infinite;
    }
  }

  &:disabled {
    border: 1px solid var(--cBtn);
    background: transparent;
    color: var(--cBtn);
    cursor: not-allowed;
  }

  &::before {
    position: absolute;
    content: "";
    inset: 0;
    background-image: var(--cPurpleBtnHover);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.25s linear;
  }

  &:not(:disabled):hover {
    &::before {
      opacity: 1;
    }
  }
}

.bridgeContainer__backBtn {
  position: relative;
  overflow: hidden;
  width: 81px;
  background: var(--cBtn);
  color: var(--cWhite);
  border: none;
  font-weight: 700;
  border-radius: var(--sRadius);
  font-size: var(--sFontBody);
  transition: background 0.25s linear;

  &:hover {
    background: var(--cBorderDark);
  }
}

.bridgeContainer__buttons {
  display: flex;
  gap: 16px;
}

.bridgeContainer__loading {
  display: flex;
  margin-left: 10px;
}
