.text-input__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.text-input {
  text-align: center;
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--cBorderDark);
  padding: 11px 0 11px;
  margin-top: 11px;
  color: var(--cWhite);

  &__error-message {
    padding: 5px 0;
    text-align: center;
    color: #39ed79;
  }

  &::placeholder {
    font-size: 1.4rem;
  }
}
