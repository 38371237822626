.rules-section {
  margin-top: 102px;
  margin-bottom: 108px;
  padding: 20px;
  max-width: 1005px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #0d0d0d;
  z-index: 3;
  span {
    font-size: 16px;
    font-weight: 600;
  }
  h2 {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 40px;
  }

  & > ol {
    li:first-child {
      list-style-type: none;
    }
  }
  ol {
    counter-reset: item;
    margin-top: 16px;
    & > li {
      margin-bottom: 16px;
    }

    &:last-child {
      li:last-child {
        margin-bottom: 0;
      }
    }
  }
  li {
    display: block;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500;
    font-size: 16px;
  }
  ol > li:before {
    padding-right: 5px;
    content: counters(item, ".") ".";
    counter-increment: item;
    color: white;
  }

  ul {
    li {
      padding-left: 20px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 5px;
        top: 50%;
        width: 5px;
        height: 5px;
        background-color: #d0d0d0;
        border-radius: 50%;
      }
    }
  }
}
