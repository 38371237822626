.transaction__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 0;

  .plug {
    width: 48px;
  }

  .header__block__transction {
    padding: 0;
  }
}

.progress {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;

  &__timestamp {
    display: flex;
    flex-direction: column;
    align-items: end;

    span {
      font-size: 16px;
    }

    span:first-child {
      font-size: 12px;
      opacity: 0.4;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    &:last-child {
      .progress__inner {
        .progress__inner__square::after {
          display: none;
        }
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    gap: 8px;

    &__square {
      width: 27px;
      height: 27px;
      border-radius: 6px;
      background-color: #303030;
      border: 1px solid #303030;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        height: 32px;
        width: 2px;
        bottom: -33px;
        left: 13px;
        background-color: #303030;
      }

      &.active {
        border-color: black;
        background-image: url("../../assets/images/tick_bcg.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.active::after {
        background: #11998e;
      }

      &.with-border {
        border-color: #49e283;
      }
    }

    &__text {
      opacity: 0.8;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 17px;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__option {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__name {
      opacity: 0.8;
    }
  }
}
