.dropdown-exchange {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  margin: 24px 0;
  margin-bottom: 8px;
  position: relative;

  .dropdown__option__value {
    display: flex;
    flex-direction: row;
  }

  .exchange_inner_input {
    display: flex;
    align-items: center;
    padding-left: 5px;
    input {
      width: 100%;
      border: none;
      border: none;
      outline: none;
      background: none;
      padding: 12px 0;
      margin: 0;
      padding-left: 18px;
      font-size: 14px;
      font-weight: 400;
      font-family: "Mulish", sans-serif;
      color: #ffffff;

      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        font-family: "Mulish", sans-serif;
      }
    }
  }

  .dropdown__value {
    border-bottom: 1px solid var(--cBorderDark);
    margin: 15px 0 0;
    padding-bottom: 15px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width: 768px) {
      width: calculate(100% - 10%);
    }

    &:not([aria-disabled="true"]):hover {
      cursor: pointer;
    }
  }

  .dropdown__arrowImage {
    transition: all 0.3s ease-in-out;
    transform: rotate(0);

    &.dropdown__arrowImage_rotated {
      transform: rotate(180deg);
    }
  }

  .dropdown__list {
    margin: 0;
    list-style-type: none;
    font-size: var(--sFontMedium);
    position: absolute;
    top: calc(100% + 10px);
    left: -1px;
    right: -1px;
    padding: 0 20px;
    padding-right: 5px;
    background: var(--cLightGrey);
    border-radius: var(--sRadiusLarge);
    z-index: 999;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(0);

    &:hover {
      cursor: pointer;
    }

    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dropdown__items-container {
    max-height: 200px;
    overflow: auto;

    &.massive {
      margin-bottom: 15px;
      overflow-y: scroll;
    }
  }

  .dropdown__listItem {
    padding: 12px 0;
    display: flex;
    gap: 12px;
    align-items: center;

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--cBorderDark);
    }
  }

  .dropdown__label {
    font-size: var(--sFontSmall);
    opacity: 0.4;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .dropdown__label_disabled {
    opacity: 0.4;
  }

  .dropdown__option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #191919;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }

  .dropdown__placeholder {
    opacity: 0.5;
  }

  .dropdown__arrow {
    height: 20px;
    width: 20px;
    background: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .number-input_exchange__input {
    position: absolute;
    right: 0;
    input[type="number"] {
      height: 22px;
      width: 100%;
      outline: none;
      border: 0;
      background: transparent;
      font-family: inherit;
      font-size: var(--sFontBody);
      color: #ffffff;
      text-overflow: ellipsis;
      text-align: right;
      white-space: nowrap;

      &::placeholder {
        color: #ffffff;
        opacity: 0.5;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .dropdown-exchange {
    width: 80vw; /* 90% of the viewport width */
    max-width: 100%;
    .dropdown__value {
      padding-bottom: 0;
      .dropdown__option__value {
        flex-direction: column;
        .dropdown__option {
          margin-bottom: 8px;
          margin-right: 0;
        }
      }
    }
  }
}
