.exchange_links {
  margin-top: 80px;
  display: flex;
  z-index: 2;
  div {
    margin-right: 16px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
